// @import '../../node_modules/bootstrap/scss/mixins/banner';
// @include bsBanner('');

/*
  Vaultside variable overrides
*/
@import 'bootstrap-overrides/variables';

/**
 * Bootstrap Required
 */
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/utilities';

/**
 * Bootstrap Optional
 */
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
// @import '../../node_modules/bootstrap/scss/images';
// @import '../../node_modules/bootstrap/scss/containers';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
// @import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/dropdown';
// @import '../../node_modules/bootstrap/scss/button-group';
@import '../../node_modules/bootstrap/scss/nav';
// @import '../../node_modules/bootstrap/scss/navbar';
@import '../../node_modules/bootstrap/scss/card';
// @import '../../node_modules/bootstrap/scss/accordion';
// @import '../../node_modules/bootstrap/scss/breadcrumb';
// @import '../../node_modules/bootstrap/scss/pagination';
@import '../../node_modules/bootstrap/scss/badge';
@import '../../node_modules/bootstrap/scss/alert';
// @import '../../node_modules/bootstrap/scss/progress';
// @import '../../node_modules/bootstrap/scss/list-group';
@import '../../node_modules/bootstrap/scss/close';
// @import '../../node_modules/bootstrap/scss/toasts';
@import '../../node_modules/bootstrap/scss/modal';
@import '../../node_modules/bootstrap/scss/tooltip';
// @import '../../node_modules/bootstrap/scss/popover';
// @import '../../node_modules/bootstrap/scss/carousel';
@import '../../node_modules/bootstrap/scss/spinners';
// @import '../../node_modules/bootstrap/scss/offcanvas';
// @import '../../node_modules/bootstrap/scss/placeholders';

// Bootstrap Helpers
@import '../../node_modules/bootstrap/scss/helpers';

// Bootstrap Utilities
@import '../../node_modules/bootstrap/scss/utilities/api';

/*
  Third party libs
*/
@import '../../node_modules/angular-notifier-updated/styles';

/*
  Vaultside custom styles
*/
@import '../app/shared/shared-base.scss';
