// Vaultside brand colors
$nile-blue: #194a5b; // for headers on white
$eastern-blue: #219ab5; // for most buttons, icons, and subheaders
$mine-shaft: #252525; // for text
$cream-can: #f5ce51; // for line accents
$seagull: #7ad1e6; // for accent colors
$corduroy: #5e5f5f; // for accent colors

$blue: $nile-blue;
$red: #a80000;
$green: #238423;
$enable-rounded: false;
