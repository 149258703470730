@mixin clearfix {
  &::before,

  &::after {
    content: ' ';
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin full-page {
  height: 100%;
  margin: 0;
  padding: 0;
}

@mixin fade-in {
  animation: fadein 0.5s;
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
