@import 'shared-mixins';

html,
body {
  @include full-page;
  font-family: 'Work Sans', sans-serif;
}

a:hover {
  color: $eastern-blue;
}

/**
 * Flex layout
 * ----------------------------------------------
 */
.vault-flex-layout {
  @include full-page;
  display: flex;
  flex-direction: column;
}

/**
 * Content
 * ----------------------------------------------
 */
.vault-flex-content {
  align-self: stretch;
  border: {
    color: $white;
    style: solid;
    width: 2px 0;
  }
  display: block;
  flex: 1 1 auto;
  position: relative;
}

.vault-content {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  .container-fluid {
    padding: 30px;
  }

  &.auto-scroll {
    overflow: auto;
  }

  .sidebar-sm & {
    left: 100px;
  }

  .sidebar-md & {
    left: 200px;
  }

  .sidebar-lg & {
    left: 300px;
  }
}

/**
 * Footer
 * ----------------------------------------------
 */
.vault-footer {
  background-color: lighten($nile-blue, 70%);
}

/**
 * Split view
 * ----------------------------------------------
 */
.vault-split-view {
  display: flex;
  flex-direction: row;

  .primary {
    flex-grow: 1;
  }

  .secondary {
    flex-shrink: 1;
  }
}

/**
 * Link rows
 */
[ng-reflect-router-link] {
  cursor: pointer;
}

/**
 * Sortable table headers
 */
.sort-header {
  white-space: nowrap;
  color: $blue;
  cursor: pointer;
  font-weight: bold;
  border-right: 2px solid $white;
  &:hover {
    color: $eastern-blue;
  }
  &:after {
    color: lighten($corduroy, 25%);
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f0dc';
    display: inline-block;
    width: 0;
    height: 0;
    right: 0;
    // margin-left: 5px;
  }
}

/**
 * No bold table headers
 */
th {
  font-weight: normal;
}

/**
 * Remove top border from table
 */
.table {
  thead {
    th {
      border-top-width: 0;
    }
  }
}

/**
 * Faded content
 */
.faded {
  opacity: 0.25;
}

/**
 * Pointer on hover
 */
.vault-list-picker {
  tbody > tr {
    cursor: pointer;
  }

  tbody > tr.ignore {
    cursor: default;
  }
}

.text-link {
  color: $link-color;
  cursor: pointer;

  &:hover {
    color: darken($link-color, 15%);
    text-decoration: underline;
  }
}

.text-muted {
  opacity: 0.5;
}

.text-strikethrough {
  text-decoration: line-through;
}

.actions-primary {
  button {
    margin-right: 10px;
  }
}

.actions-secondary {
  button {
    margin-left: 10px;
  }
}

@media print {
  .actions {
    display: none;
  }
}

/**
 * Table layout cheats
 */
.vault-table {
  display: table;
}

.vault-row {
  display: table-row;
}

.vault-cell {
  display: table-cell;
  padding: 0 5px;
}

.btn + .btn {
  margin-left: 5px;
}
.btn-group {
  .btn + .btn {
    margin-left: 1px;
  }
}
.primary-column {
  color: $link-color;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
}

ngb-modal-window {
  // PDF
  &.pdf-modal {
    .modal-dialog {
      bottom: 10px;
      display: block;
      left: 10px;
      margin: 0;
      max-width: none !important;
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .modal-content {
      bottom: 0;
      display: block;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
    }

    .pdf-wrapper {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .close-button {
      position: absolute;
    }

    .close-button {
      bottom: 10px;
      left: 17px;
    }
  }

  // Drawer
  &.vault-drawer {
    left: inherit;
    width: 500px;
    right: 0;

    .modal-dialog {
      bottom: 0;
      display: block;
      margin: 0;
      max-width: none; // !important;
      position: absolute;
      right: 0;
      top: 0;
      width: 500px;
    }

    .modal-content {
      bottom: 0;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

/**
 * Override pagination style
 */
ul.ngx-pagination {
  padding-left: 0;
  li.current {
    background: $blue;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

/**
 * Make the page tabs stand out a little
 */
vault-page-tabs {
  a {
    font-weight: 600;
  }
  .nav-tabs {
    .nav-link:hover,
    .nav-link:focus {
      border-color: transparent;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.fade-in {
  @include fade-in;
}

/**
 * Color helpers
 */
.nile-blue {
  &-color {
    color: $nile-blue;
  }
  &-background {
    background-color: $nile-blue;
  }
}
.easter-blue {
  &-color {
    color: $eastern-blue;
  }
  &-background {
    background-color: $eastern-blue;
  }
}
.mine-shaft {
  &-color {
    color: $mine-shaft;
  }
  &-background {
    background-color: $mine-shaft;
  }
}
.cream-can {
  &-color {
    color: $cream-can;
  }
  &-background {
    background-color: $cream-can;
  }
}
.seagull {
  &-color {
    color: $seagull;
  }
  &-background {
    background-color: $seagull;
  }
}
.corduroy {
  &-color {
    color: $corduroy;
  }
  &-background {
    background-color: $corduroy;
  }
}

.inline-block {
  display: inline-block !important;
}
